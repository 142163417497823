<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="terms-conditions">
                © {{ new Date().getFullYear() }} <a target="_blank" href="https://dynamicmarkets.com/">Dynamic Markets</a> is a trading name of Global
                Dynamic Markets Limited. Global Dynamic Markets Limited is licensed and regulated by the Financial Services
                Commission of Mauritius and hold an Investment Dealer (Full Service Dealer Excluding Underwriting) License
                bearing license number GB21026278. The company registered office address is 7A Mayer Street, Port-Louis,
                Republic of Mauritius.
                <a href="https://dynamicmarkets.com/docs/Cookies_Policy.pdf" target="_blank">Cookie Preferences</a>,
                <a href="https://dynamicmarkets.com/docs/Data_Protection_Policy.pdf" target="_blank">Privacy</a>, and
                <a href="https://dynamicmarkets.com/docs/Terms_of_Use.pdf" target="_blank">Terms and
                    Conditions</a>.
            </p>
        </div>
        <div class="footer-section f-section-2">
            <p class="">
                Coded with A
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-heart">
                    <path
                        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                    </path>
                </svg>
                G
            </p>
        </div>
    </div>
    <!-- END FOOTER -->
</template>
